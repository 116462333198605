var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"api":"customer","filters":_vm.filters,"headers":_vm.headers,"popup":_vm.popup,"save":_vm.save,"edit":_vm.edit,"compute":_vm.compute,"selectable":""},on:{"click:item":function($event){return _vm.edit( $event.item.item )}},scopedSlots:_vm._u([{key:"item.value.pics",fn:function(ref){
var value = ref.value;
return [_c('d-image',{attrs:{"value":value ? value[0] : undefined,"width":_vm.imageDimension,"height":_vm.imageDimension,"color":value && value.length ? 'transparent' : undefined,"item-image":"url","hide-placeholder":"","outlined":"","contain":""}})]}},{key:"item.value.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('p',{staticClass:"subtitle-2 mb-1"},[_vm._v(" "+_vm._s(value)+" ")]),(item.mail)?_c('p',{staticClass:"caption mb-1"},[_vm._v(" "+_vm._s(item.mail)+" ")]):_vm._e(),(item.phone)?_c('p',{staticClass:"caption mb-0"},[_vm._v(" "+_vm._s(item.phone)+" ")]):_vm._e()]}},{key:"item.value.category",fn:function(ref){
var item = ref.item;
return [(item.categoryId)?_c('category',{attrs:{"item":item,"item-value":"categoryId","item-text":"categoryName","small":"","link":""}}):_vm._e()]}},{key:"item.value.filterItems",fn:function(ref){
var value = ref.value;
return [(value.length)?_vm._l((value),function(filterItem,index){return _c('category-filter-item',{key:index,staticClass:"ma-1",attrs:{"item":filterItem,"item-value":"id","item-text":"name","color":"grey lighten-3","small":"","link":""}})}):_c('span')]}},{key:"item.value.tags",fn:function(ref){
var value = ref.value;
return [(value && value.length)?_vm._l((value),function(tag,index){return _c('tag',{key:index,staticClass:"ma-1",attrs:{"item":tag,"item-value":"tagId","item-text":"tagName","small":"","link":""}})}):_c('span')]}},{key:"item.value.description",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"three-line-text"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.value.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"one-line-text"},[_c('btn',{staticClass:"mr-2",attrs:{"color":"primary","icon-right":"mdi-eye-outline","to":("/offer?customerId=" + (item.id)),"small":""}},[_vm._v(" "+_vm._s(_vm.$t('offer.title'))+" ")]),_c('btn',{attrs:{"icon":"mdi-map-marker","color":"secondary"},on:{"click":function($event){_vm.selected = item}}})],1)]}},{key:"form",fn:function(){return [_c('customer-form',{ref:"form",attrs:{"is-new":_vm.isNew,"save":_vm.save},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"footer",fn:function(){return [_c('v-spacer'),_c('btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.exporting},on:{"click":function($event){$event.stopPropagation();return _vm.exportData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.export'))+" ")]),_c('btn',{on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('customer.btn.new'))+" ")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('m-dialog',{attrs:{"title":_vm.$t('inputs.location.label'),"actions":false,"max-width":"720"},model:{value:(_vm.showMap),callback:function ($$v) {_vm.showMap=$$v},expression:"showMap"}},[(_vm.selected)?_c('google-maps',{attrs:{"value":_vm.selected,"latitude-key":"latitude","longitude-key":"longitude","min-height":"480"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }