<template>
  <table-view
    ref="table"
    api="customer"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :save="save"
    :edit="edit"
    :compute="compute"
    @click:item="edit( $event.item.item )"
    selectable
  >
  <template v-slot:item.value.pics="{ value }">

    <d-image
      :value="value ? value[0] : undefined"
      :width="imageDimension"
      :height="imageDimension"
      :color="value && value.length ? 'transparent' : undefined"
      item-image="url"
      hide-placeholder
      outlined
      contain
    />

  </template>
  <template v-slot:item.value.name="{ item, value }">

    <p class="subtitle-2 mb-1">
      {{ value }}
    </p>

    <p class="caption mb-1" v-if="item.mail">
      {{ item.mail }}
    </p>

    <p class="caption mb-0" v-if="item.phone">
      {{ item.phone }}
    </p>

  </template>
  <template v-slot:item.value.category="{ item }">

    <category
      v-if="item.categoryId"
      :item="item"
      item-value="categoryId"
      item-text="categoryName"
      small link
    />

  </template>
  <template v-slot:item.value.filterItems="{ value }">
    <template v-if="value.length">

      <category-filter-item
        class="ma-1"
        v-for="( filterItem, index ) in value"
        :key="index"
        :item="filterItem"
        item-value="id"
        item-text="name"
        color="grey lighten-3"
        small link
      />

    </template>
    <span v-else/>
  </template>
  <template v-slot:item.value.tags="{ value }">
    <template v-if="value && value.length">

      <tag
        class="ma-1"
        v-for="( tag, index ) in value"
        :key="index"
        :item="tag"
        item-value="tagId"
        item-text="tagName"
        small link
      />

    </template>
    <span v-else/>
  </template>
  <template v-slot:item.value.description="{ value }">

    <div class="three-line-text">
      {{ value }}
    </div>

  </template>
  <template v-slot:item.value.actions="{ item }">

    <div class="one-line-text">
      <btn
        color="primary"
        icon-right="mdi-eye-outline"
        class="mr-2"
        :to="`/offer?customerId=${item.id}`"
        small
      >
        {{ $t('offer.title') }}
      </btn>

      <btn
        @click="selected = item"
        icon="mdi-map-marker"
        color="secondary"
      />
    </div>

  </template>
  <template v-slot:form>

    <customer-form
      ref="form"
      v-model="model"
      :is-new="isNew"
      :save="save"
    />

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('customer.btn.new') }}
    </btn>

  </template>

  <m-dialog
    v-model="showMap"
    :title="$t('inputs.location.label')"
    :actions="false"
    max-width="720"
  >
    <google-maps
      v-if="selected"
      :value="selected"
      latitude-key="latitude"
      longitude-key="longitude"
      min-height="480"
    />
  </m-dialog>

  </table-view>
</template>

<script>
import DImage from '@/components/Image';
import TableView from '@/components/TableView';
import GoogleMaps from '@/components/GoogleMaps';
import CustomerForm from './Form';
import Category from '@/components/values/Category';
import CategoryFilterItem from '@/components/values/CategoryFilterItem';
import Tag from '@/components/values/Tag';
import { ICONS } from '@/utils/constants';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Customers',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('customer.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('customer.title'),
    icon: ICONS.customer
  }),
  components: {
    TableView,
    CustomerForm,
    GoogleMaps,
    DImage,
    Category,
    CategoryFilterItem,
    Tag
  },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    isNew: false,
    filters: [],
    popup: { maxWidth: 1200 },
    showMap: false,
    selected: null
  }),
  computed: {
    ...mapState( 'app', [ 'isMobile' ]),
    ...mapGetters( 'app', [ 'imageDimension' ]),
    headers() {
      return [
        {
          key: 'pics',
          text: this.$t('inputs.pics.label'),
          mobile: true,
          width: this.imageDimension,
          sorteable: false,
          filter: false
        },
        {
          key: 'name',
          text: this.$t('inputs.name.label'),
          mobile: true,
        },
        {
          key: 'mail',
          text: this.$t('inputs.email.label'),
          hide: true
        },
        {
          key: 'phone',
          text: this.$t('inputs.phone.label'),
          hide: true
        },
        {
          key: 'category',
          text: this.$t('inputs.idCategory.label'),
          filter: {
            is: 'api-field',
            api: 'category',
            itemSearch: 'name',
            clearable: true,
            multiple: true
          }
        },
        {
          key: 'filterItems',
          text: this.$t('inputs.filterItems.label'),
          filter: false
        },
        {
          key: 'tags',
          text: this.$t('inputs.tag.label'),
          filter: {
            is: 'api-field',
            api: 'tag',
            itemText: 'value',
            itemSearch: 'value',
            clearable: true,
            multiple: true
          }
        },
        {
          key: 'description',
          text: this.$t('inputs.description.label'),
          width: 560
        },
        {
          key: 'actions',
          text: ' ',
          width: 1,
          sorteable: false,
          filter: false
        }
      ];
    },
  },
  watch: {
    selected( item ) {
      this.showMap = !!item;
    },
    showMap( value ) {
      ! value && (this.selected = null);
    }
  },
  methods: {
    compute( items ) {
      ( items || [] ).forEach( item => {
        item.filterItems = ( item.filterItems || [] ).map( v => v.filterItem );
      });
      return items;
    },
    edit( item ) {

      this.dialog = true;
      this.isNew = !item;
      this.popup = {
        ...this.popup,
        title: this.$t(`customer.btn.${ item ? 'edit': 'new' }`)
      };

      if ( ! this.isNew ) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.model = {
          id: -1,
          status: 1,
          ...item
        };
      });
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    sanitize( model ) {
      return {
        ...model,
        address: model.address ? model.address.title : '',
        category: model.categoryId,
        categoryId: undefined,
        categoryName: undefined,
        filterItems: undefined,
        pics: undefined,
        categoryFilterItems: ( model.filterItems || [] ),
        pictures: ( model.pics || [] ).map( f => f.id || f.picId ),
        tags: ( model.tags || [] ).map( f => f.id || f.tagId )
      };
    },
    save() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.model = this.$refs.form.model;
        this.$store
          .dispatch('api/customer/set', this.sanitize( this.model ))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          });
      }
    },
  }
}
</script>
